import * as Y from 'yjs';
import './App.scss';
import './fonts/line-awesome/css/line-awesome.min.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { YManager } from '@property-folders/common/offline/yManager';
import Dexie from 'dexie';
import { IndexeddbPersistence } from 'y-indexeddb';
import { bind } from 'immer-yjs';
import { getPathParentAndIndex, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { seoFriendlySlug, ShortId } from '@property-folders/common/util/url';
import { fromBase64 } from 'lib0/buffer';
import { cloneDeep } from 'lodash';
import { OfflineProperties } from '@property-folders/common/offline/offlineProperties';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { MaterialisedPropertyData, YDocContentType } from '@property-folders/contract';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { Predicate } from '@property-folders/common/predicate';
import {
  FormCode,
  PropertyRootKey,
  META_APPEND,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';
import { useInterval } from 'react-use';
import { FileStorage, StorageItemFileStatus, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { v4 } from 'uuid';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { Awareness } from 'y-protocols/awareness';
import { OnlineContext } from '@property-folders/components/context/online';
import { PwaContext } from '@property-folders/components/context/pwa';
import { useAddToHomescreenPrompt } from '@property-folders/components/hooks/useAddToHomeScreenPrompt';
import { NotesDisplay } from '~/NotesDisplay';
import { ValidSessionApp } from '~/ValidSessionApp';
import { UnauthenticatedRoutedApp } from '~/UnauthenticatedRoutedApp';
import { alwaysBypassRoutes } from '~/alwaysBypassRoutes';
import { Pdf } from '@property-folders/common/util/pdf-worker/Pdf';
import { db } from '@property-folders/common/offline/db';
import { Rum } from '@property-folders/components/telemetry/Rum';
import { YFormUtil } from '@property-folders/common/util/yform';
export interface RouterData {
  transId: string,
  ydoc: Y.Doc,
  localProvider: IndexeddbPersistence,
  ydocStats: Y.Doc,
  localProviderStats: IndexeddbPersistence,
  awareness: Awareness,
}

declare global {
  interface Window {
    fnDev: any
    reaformsGlobal: {
      version?: any;
    }
  }
}

window.fnDev = window.fnDev || {};
window.reaformsGlobal = window.reaformsGlobal || {};
if (import.meta.env.DEV) {
// devs: you can call this when running locally so you get back a real session token via the proxy.
// supply your own email/password, as though it were the login screen.
  window.fnDev.login = AjaxPhp.login;
  window.fnDev.clearUntracked = async (agentId: number) => {
    const keepIds = new Set((await OfflineProperties.all(agentId)).map(i => i.id));
    const dbNames = await Dexie.getDatabaseNames();
    for (const name of dbNames) {
      if (keepIds.has(name)) {
        continue;
      }
      const uuidMatch = name.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i);
      if (!uuidMatch) {
        continue;
      }

      console.log(`deleting database ${name}`);
      await Dexie.delete(name);
    }
  };
  window.fnDev.seoFriendlySlug = seoFriendlySlug;
  window.fnDev.Y = Y;
  window.fnDev.ShortId = ShortId;
  window.fnDev.showPathDefinitionWarnings = true;
  window.fnDev.rootKeys = PropertyRootKey;
  const generateRootKeyList = async (idOrShort?: string): Promise<string[]> => {
    if (!idOrShort) {
      const BASEPATH = '/properties';
      idOrShort = window.location.pathname.replace(BASEPATH, '').split('/').filter(Predicate.isTruthy)[0];
      console.log(idOrShort);
    }
    const iid = ShortId.toUuid(idOrShort);

    const doc = new Y.Doc();
    const localProvider = new IndexeddbPersistence(iid, doc);
    await localProvider.whenSynced;

    const list = [`${PropertyRootKey.Data}`, `${PropertyRootKey.Meta}`];
    const knownRoots = (doc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData).sublineageRoots;
    list.push(...(knownRoots??[]).map(key=>([key,key+META_APPEND])).flat());
    return list;
  };

  window.fnDev.messUpFileStorage = (fileId: string) => {
    db.transaction('rw', db.fileData, db.fileMeta, async () => {
      await db.fileMeta.delete(fileId);
      await db.fileData.update(fileId, {
        syncStatus: StorageItemSyncStatus.None,
        fileStatus: StorageItemFileStatus.Failed
      });
    });
  };

  const loadProperty = (idOrShort?: string) => {
    if (!idOrShort) {
      const BASEPATH = '/properties';
      idOrShort = window.location.pathname.replace(BASEPATH, '').split('/').filter(Predicate.isTruthy)[0];
      console.log(idOrShort);
    }
    const iid = ShortId.toUuid(idOrShort);
    console.log(iid);
    const doc = new Y.Doc();
    const localProvider = new IndexeddbPersistence(iid, doc);

    return { doc, localProvider, iid };
  };

  window.fnDev.loadLocalProperty = async (idOrShort?: string, dumpKey?: number | string) => {

    const { doc, localProvider, iid } = loadProperty(idOrShort);
    if (dumpKey != null) {
      if (typeof dumpKey === 'number' && dumpKey > 1) {
        const keyList = await generateRootKeyList(iid);
        dumpKey = keyList[dumpKey];
      }
      await new Promise((resolve, reject) => {
        localProvider.whenSynced.then(resolve).catch(reject);
      });
      return window.fnDev.dumpDoc(localProvider.doc, dumpKey);
    }
    return { doc,  localProvider };
  };

  window.fnDev.findSublineageFromCurrentForm = async (props: {dumpTag?: 'meta' | 'data', remoteQueryInstructions?: boolean}) => {
    const { dumpTag, remoteQueryInstructions } = props??{};
    const { doc, localProvider } = await loadProperty();
    await localProvider.whenSynced;
    const urlSegments = window.location.pathname.split('/').filter(Predicate.isTruthy);
    if (urlSegments.length < 4) {
      console.log('Not currently in a form');
      return;
    }
    const maybeFormId = ShortId.toUuid(urlSegments[3]);
    const { dataRootKey, metaRootKey, instance } = YFormUtil.getFormLocationFromId(maybeFormId, doc) ?? {};
    // We console so that we don't have to await this
    console.log('lineage key:', dataRootKey);
    if (dumpTag === 'meta') {
      console.log(doc.getMap(metaRootKey).toJSON());
    } else if (dumpTag === 'data') {
      console.log(doc.getMap(dataRootKey).toJSON());
    }

    if (remoteQueryInstructions && instance) {
      const sessionId = instance?.signing?.session?.id;
      console.log('Current session ID', sessionId);
      console.log(`Run the following query in the web console dynamo table view:
Query
Index: GSI 1
g1pk 'FORM#${instance.id}'
g1sk begins with 'PARTY#' and look out for anything with REMOTE_SIGN_SESSION after the party ID
`);
    }

  };

  window.fnDev.loadLocalDoc = (id: string) => {
    const doc = new Y.Doc();
    const localProvider = new IndexeddbPersistence(id, doc);
    return { doc, localProvider };
  };

  window.fnDev.dumpKnownRootKeys = async (idOrShort?: string) => {
    const list = await generateRootKeyList(idOrShort);
    for (const [i,v] of list.entries()) {
      if (i === 0) {
        console.log(i+':', v, 'Main data root key');
        continue;
      }
      if (i === 1) {
        console.log(i+':', v, 'Main meta root key');
        continue;
      }
      if (v.endsWith(META_APPEND)) {
        const baseId = v.slice(0,v.length-META_APPEND.length);
        console.log(i+':', v, ShortId.fromUuid(baseId));
        continue;
      }
      console.log(i+':', v, ShortId.fromUuid(v));
    }
  };
  window.fnDev.dumpDoc = (doc: Y.Doc, key: string | 0 | 1 = 0) => {
    if (typeof key === 'number') {
      if (key === 1) return doc.getMap(PropertyRootKey.Meta).toJSON();
      return doc.getMap(PropertyRootKey.Data).toJSON();
    }
    return doc.getMap(key).toJSON();
  };

  window.fnDev.createDebugSublineage = async (idOrShort?: string) => {
    // It is worth noting that this may not be fully synchronised with the network ydoc
    const { doc, localProvider, iid } = loadProperty(idOrShort);
    await localProvider.whenSynced;
    const newRootKey = v4();
    const newMetaRootKey = newRootKey+META_APPEND;
    const parentMetaBinder = bind(doc.getMap(PropertyRootKey.Meta));
    const now = new Date();
    const newDataBinder = bind(doc.getMap(newRootKey));
    const newMetaBinder = bind(doc.getMap(newMetaRootKey));
    parentMetaBinder.update((draft: TransactionMetaData)=>{
      if (!Array.isArray(draft.sublineageRoots)) {
        draft.sublineageRoots = [];
      }
      draft.sublineageRoots.push(newRootKey);
    });
    const parentMeta = parentMetaBinder.get() as TransactionMetaData;
    newMetaBinder.update((draft:TransactionMetaData) => {
      draft.createdUtc = now.toISOString();
      draft.entity = parentMeta.entity;
      draft.creator = parentMeta.creator;

    });
    handleNewForm(doc, FormCode.RSC_ContractOfSale, undefined, {}, newRootKey, newMetaRootKey);
    newDataBinder.update((draft: MaterialisedPropertyData) => {
      Object.assign(draft, doc.getMap(PropertyRootKey.Data).toJSON());
    });
  };

  window.fnDev.undefinePath = async (doc: Y.Doc | null, key: string = PropertyRootKey.Data, path: PathType) => {
    const { doc: activeDoc, localProvider } = loadProperty();
    if (!doc) {
      await localProvider.whenSynced;
      doc = activeDoc;
    }
    const pathStr = normalisePathToStr(path);
    const binder = bind(doc.getMap(key));
    binder.update((draft) => {
      const { indexer, parent } = getPathParentAndIndex(pathStr, draft);
      delete parent[indexer];
      // Console.log exists because we cannot return from an update to print to the devtools console.
      // returning a value here will affect the update. So we need to log here
      console.log(cloneDeep(draft));
    });
  };
  window.fnDev.setPath = async (doc: Y.Doc | null, key: string = PropertyRootKey.Data, path: PathType, value: any) => {
    const { doc: activeDoc, localProvider } = loadProperty();
    if (!doc) {
      await localProvider.whenSynced;
      doc = activeDoc;
    }
    const pathStr = normalisePathToStr(path);
    const binder = bind(doc.getMap(key));
    binder.update((draft) => {
      const { indexer, parent } = getPathParentAndIndex(pathStr, draft);
      parent[indexer] = value;
      // Console.log exists because we cannot return from an update to print to the devtools console.
      // returning a value here will affect the update. So we need to log here
      console.log(cloneDeep(draft));
    });
  };

  window.fnDev.Y = Y;
  window.fnDev.loadDocFromBase64 = (state: string) => {
    const doc = new Y.Doc();
    Y.applyUpdate(doc, fromBase64(state));
    return doc;
  };
  window.fnDev.hasPending = YManager.hasPendingOutboundMessages;
  window.fnDev.printGc = () => {
    YManager.instance().printGc();
  };
  window.fnDev.gc = () => {
    return YManager.instance().garbageCollect();
  };

  window.addEventListener('beforeunload', () => {
    YManager.unbind();
  });

  window.fnDev.setNoSigSuggestion = (setVal: boolean) => {
    window.fnDev.noSigSuggestion = setVal == undefined ? true : setInterval;
  };

  window.fnDev.focusDebuggerEnabled = false;
  window.fnDev.enableFocusDebugger = () => {
    window.fnDev.focusDebuggerEnabled = true;
  };

  window.fnDev.yApplyProperty = (idOrShort: string | undefined, fn: (state: MaterialisedPropertyData) => void) => {
    const iid = idOrShort
      ? ShortId.toUuid(idOrShort)
      : window.location.pathname
        .split('/')
        .filter(Predicate.isTruthy).map(s => {
          try {
            return ShortId.toUuid(s);
          } catch {
            return undefined;
          }
        })
        .filter(Predicate.isTruthy)[0];
    if (!iid) {
      throw new Error('Could not determine/parse out id');
    }

    const { doc, localProvider } = YManager.instance().get(iid, YDocContentType.Property, {});

    localProvider.whenSynced.finally(() => {
      console.log('localProvider synced');
      if (!localProvider.synced) {
        console.error('local provider not synced');
        return;
      }

      const binder = bind<MaterialisedPropertyData>(doc.getMap(PropertyRootKey.Data));
      binder.update(fn);
      console.log('applied');
    });
  };

  window.fnDev.readFileFromStore = (fileKey: string) => {
    return FileStorage.read(fileKey);
  };

  window.fnDev.deleteFileFromStore = (fileKey: string) => {
    return FileStorage.delete(fileKey);
  };

  window.fnDev.queueDownload = FileStorage.queueDownload;
  window.fnDev.writeFile = FileStorage.write;

  // This should only pass if we're in dev mode
  window.fnDev.generateDoc = (contentArrayOrWholeObject: any) => {
    const dd = Array.isArray(contentArrayOrWholeObject)
      ? {
        content: contentArrayOrWholeObject
      }
      : contentArrayOrWholeObject;
    const pdf = new Pdf();
    pdf
      .prepare({
        headingColour: '#000000',
        lineColour: '#000000',
        agencyContact: { agencyEmail: 'test@eckermanns.com.au', agencyName: 'fnDev Agency', agencyPhone: '0400 000 000', agencyRla: '123 456' }
      }, {}, false)
      .generateBlob(dd, (blob) => {
        const nc = document.createElement('a');
        nc.setAttribute('href', URL.createObjectURL(blob));
        nc.setAttribute('target', '_blank');
        document.querySelector('body')?.appendChild(nc);
        nc.click();
        nc.remove();
      });
  };

}

export type AgentInfoState = {
  agentId: number;
  agentUuid: string;
  offlineProperties: boolean;
  propertyFoldersEnabled: boolean;
  enableWebSockets: boolean;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      networkMode: 'offlineFirst'
    }
  }
});

async function pollReaforms(): Promise<boolean> {
  if (!window.navigator.onLine) return false;
  /*
  const host = location.hostname;
  if (host === 'localhost' || host.startsWith('127.')) {
    return window.navigator.onLine;
  }
  */
  try {
    const req = await fetch('/api/rest/ping', { credentials: 'omit', referrer: '', headers: {}, mode: 'cors', cache: 'no-store' });
    if (req.ok) return true;
    return false;
  } catch {
    return false;
  }
}

const handleSetOnlineFactory = (_setOnline: React.Dispatch<React.SetStateAction<boolean>>) => (newOnlineState: boolean) => {
  _setOnline(ps => {
    // Don't like doing side effects in here, but just reading online seemed to be giving
    // me a stale value
    if (typeof newOnlineState === 'boolean' && newOnlineState !== ps) {
      if (newOnlineState) {
        navigator.serviceWorker?.ready.then(reg=>{
          reg.active.postMessage({ windowOnline: true });
        });
      } else {
        navigator.serviceWorker?.ready.then(reg=>{
          reg.active.postMessage({ windowOnline: false });
        });
      }
    }
    return typeof newOnlineState === 'boolean' ? newOnlineState : ps;
  });
};

export const App = () => {
  const url = new URL(window.location.href);
  const operateUnauthenticated = alwaysBypassRoutes.findIndex(r => url.pathname.startsWith(`/${r}`)) !== -1;
  const [lastPolledNewVersion, setLastPolledNewVersion] = useState<string|null>(null);
  const [updatedSwActivated, setUpdatedSwActivated] = useState(false);

  const [online, _setOnline] = useState(window.navigator.onLine);

  const handleSetOnline = handleSetOnlineFactory(_setOnline);

  const handleCheckOnline = useCallback(()=>{
    pollReaforms().then(res=>{
      handleSetOnlineFactory(_setOnline)(res);
    });
  }, []);

  useInterval(handleCheckOnline, 20000);

  const workerRegistration = useRef<ServiceWorkerRegistration | null>(null);

  useEffect(()=>{
    const workerContainer = navigator.serviceWorker as ServiceWorkerContainer;
    const loadOrRegisterSw = async () => {
      if ('serviceWorker' in navigator) {
        const workerContainer = navigator.serviceWorker as ServiceWorkerContainer;

        let maybeReg = await workerContainer.getRegistration('/');
        if (!maybeReg) {
          maybeReg = await workerContainer.register('/serviceworker.js', { type: 'classic', scope: '/' });
          if (maybeReg) workerRegistration.current = maybeReg;
        } else {
          if (maybeReg) workerRegistration.current = maybeReg;
          runSwUpdate();
        }
      }
    };

    const unregisterMarketingSw = async () => {
      const maybeReg = (await workerContainer.getRegistrations()).filter(sw=>{
        const url = new URL(sw.scope);
        const path = url.pathname;
        const pathSegments = path.split('/').filter(s=>s);
        if (pathSegments.length === 1 && pathSegments[0] === 'home') return true;
        return false;
      });
      for (const reg of maybeReg) {
        reg.unregister();
      }
    };

    loadOrRegisterSw();
    unregisterMarketingSw();
    // Don't forget to also remove ServiceWorkerRegister in the marketing site from the root

    function offlineEvent() {
      handleSetOnline(false);
    }
    const onlineEvent = handleCheckOnline;
    window.addEventListener('online', onlineEvent);
    window.addEventListener('offline', offlineEvent);

    handleCheckOnline();

    return ()=>{
      window.removeEventListener('online', onlineEvent);
      window.removeEventListener('offline', offlineEvent);
    };
  }, []);

  const runSwUpdate = async () => {
    if (!workerRegistration.current) return;
    setUpdatedSwActivated(false);
    const registrationUpdatingListener = () => {
      const workingSw = workerRegistration.current?.installing;
      if (!workingSw) return;
      const stateChangeListener = async () => {
        if (workingSw.state === 'activated') {
          setUpdatedSwActivated(true);
          workingSw.removeEventListener('statechange', stateChangeListener);
        }
      };
      workingSw.addEventListener('statechange', stateChangeListener);
    };
    workerRegistration.current.addEventListener('updatefound', registrationUpdatingListener);
    await workerRegistration.current.update();
  };

  useEffect(()=>{
    if (!lastPolledNewVersion) return;
    if (!workerRegistration.current) return; // Unfortunate
    runSwUpdate();
  }, [lastPolledNewVersion]);

  const pwa = useAddToHomescreenPrompt();

  const setInitialVersion = async () => {
    try {
      const versionSpecifier = (await (await fetch(`/version.json?id=${__COMMIT_HASH__}`)).json()).version;
      window.reaformsGlobal.version = versionSpecifier;
    } catch {
      console.error('Could not retrieve current deployed version');
    }
  };

  if (!window.reaformsGlobal.version) {
    // Setting base version
    window.reaformsGlobal.version = import.meta.env.GIT_VERSION;
    setInitialVersion();
  }
  const isOnline = online;

  const pollVersion = async () => {
    if (!isOnline) return;
    try {
      const latestVersion = (await (await fetch(`/version.json?id=${__COMMIT_HASH__}`)).json()).version;

      const noneReactPages = [
        '/legacy/forms.php',
        '/forms.php',
        '/legacy/remotecompletion.php',
        '/remotecompletion.php',
        '/legacy/iframe_editor.php',
        '/iframe_editor.php',
        '/legacy/form_iframe.php',
        '/form_iframe.php'
      ];

      if (window.reaformsGlobal.version != latestVersion && !noneReactPages.some((noneReactPathname) => url.pathname.startsWith(noneReactPathname))) {
        setLastPolledNewVersion(latestVersion);
      }
    } catch {
      console.error('Could not retrieve current deployed version for forcing reload');
    }
  };

  useInterval(async () => {
    pollVersion();
    // If we're offline, only attempt the ping
  }, isOnline ? 5*60*1000 : null);

  useEffect(() => {
    // poll version as soon as we're online, don't wait for interval
    pollVersion();
  }, [isOnline]);

  if (url.pathname.startsWith('/forms.php') && url.searchParams.has('AuthToken') && url.searchParams.has('UserToken')) {
    window.location.href = LinkBuilder.loginPage(
      `${url.pathname}?DocumentID=${url.searchParams.get('DocumentID')}`,
      {
        AuthToken: url.searchParams.get('AuthToken') as string,
        UserToken: url.searchParams.get('UserToken') as string
      }
    ).toString();

    return null;
  }

  if (url.pathname.startsWith('/forms.php') && url.searchParams.has('code')) {
    window.location.href = LinkBuilder.loginPage(
      `${url.pathname}?DocumentID=${url.searchParams.get('DocumentID')}`,
      {
        code: url.searchParams.get('code') as string
      }
    ).toString();

    return null;
  }

  useEffect(() => {
    // if the app's just starting up, there won't be any background tasks yet.
    // just in case they didn't clean up properly (i.e. HMR shenanigans), do it for them now.
    YManager.clearLock();
    FileSync.clearLock();
  }, []);
  return <>
    <Rum />
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <OnlineContext.Provider value={online}>
          <PwaContext.Provider value={pwa}>
            {// Prevent any form of login checks to auth/session/info by not going in via ValidSessionApp
              operateUnauthenticated
                ? <UnauthenticatedRoutedApp routes={alwaysBypassRoutes}/>
                : <ValidSessionApp needsReload={!!(lastPolledNewVersion&&updatedSwActivated)} onYManagerDisconnect={handleCheckOnline}/>
            }
            <NotesDisplay/>
          </PwaContext.Provider>
        </OnlineContext.Provider>
      </Provider>
    </QueryClientProvider>
  </>;
};

export default App;
