import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useWindowSize } from 'react-use';
import { DevicePreferences, useDevicePreferences } from '@property-folders/components/hooks/useDevicePreferences';
import React, { useEffect, useRef, useState } from 'react';
import clsJn from '@property-folders/common/util/classNameJoin';
import {
  SetupSideNavMenuSizingContext
} from '@property-folders/components/dragged-components/SideNavMenuSizingContext';
import { SideNavMenu } from '@property-folders/components/dragged-components/SideNavMenu/SideNavMenu';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { WholePageApology } from '@property-folders/components/display/errors/major-crash';
import { useMatches } from 'react-router-dom';
import { SideNavFallback } from '@property-folders/components/display/errors/sidebar';
import { checkIfIndexedDBShimNeeded } from '@property-folders/components/shims/IndexedDB/checkIfIndexedDBShimNeeded';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import useLocalStorageState from 'use-local-storage-state';
import { IosInstallAppHint } from '@property-folders/components/display/IosInstallAppHint';

export function AppContainer({ children, showSideBar = true, moreSeriousOffline = false, ...props }: {
  children: any,
  onLogoutClick: () => void;
  showSideBar?: boolean;
  moreSeriousOffline?: boolean
}) {
  const matches = useMatches();
  const matchReset = matches.map(match => match.pathname);
  const isMinWidthLg = useMediaQuery({ minWidth: BP_MINIMA.lg });
  const windowSize = useWindowSize();
  const { devicePreferences, setDevicePreferences } = useDevicePreferences();
  const [dp] = useLocalStorageState<DevicePreferences>('devicePreferences');

  const [toggle, setToggle] = useState(devicePreferences?.sidebar.collapseAt ? windowSize.width > devicePreferences.sidebar.collapseAt : isMinWidthLg);
  const sideNavRef = useRef<HTMLElement>(null);
  const isOnline = useOnline();
  useEffect(() => {
    checkIfIndexedDBShimNeeded()
      .then(needed => {
        if (needed) {
          console.warn('Using IndexedDB Blob workaround');
        }
      });
  }, []);

  const handleShowSideMenu = () => {
    setToggle(cv => {
      const newExpand = !cv;
      setDevicePreferences(s => {
        s.sidebar = {
          collapseAt: newExpand ? windowSize.width - 1 : Number.MAX_SAFE_INTEGER
        };
        return s;
      });
      return newExpand;
    });
  };

  useEffect(() => {
    setToggle(devicePreferences?.sidebar.collapseAt ? windowSize.width > devicePreferences.sidebar.collapseAt : isMinWidthLg);
  }, [windowSize, devicePreferences, dp]);

  const offlineBanner = moreSeriousOffline
    ? <small className='p-0 m-0 w-100 bg-warning text-center flex-grow-0'>No connection - this feature requires an Internet connection to save changes</small>
    : <small className='p-0 m-0 w-100 bg-light text-center flex-grow-0'>No connection - your changes will be uploaded when you have an Internet connection</small>;
  return <>
    <IosInstallAppHint />
    <div className='fill-space d-flex flex-column'>
      {!isOnline && offlineBanner}
      <div className={clsJn('d-flex flex-column flex-grow-1', showSideBar ? 'sidenav' : 'sidenav-hidden')}>
        <div className={clsJn('below-header flex-grow-1 h-100 overflow-hidden', toggle && 'expand')}>
          <SetupSideNavMenuSizingContext sideNavRef={sideNavRef}>
            <ErrorBoundary FallbackComponent={WholePageApology} resetKeys={matchReset}>
              <div className='content-container'>
                {/*stop chrome autocomplete*/}
                <form style={{ display: 'none' }}></form>
                {children}
              </div>
            </ErrorBoundary>
            {showSideBar && <ErrorBoundary FallbackComponent={SideNavFallback}>
              <div className={clsJn('expansion-mask', toggle && 'expand')} onClick={() => setToggle(false)}></div>
              <SideNavMenu ref={sideNavRef} expanded={toggle} onExpandToggle={handleShowSideMenu} onLogoutClick={props.onLogoutClick}></SideNavMenu>
            </ErrorBoundary>}
          </SetupSideNavMenuSizingContext>
        </div>
      </div>
    </div>
  </>;
}
