import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Navigate,
  NonIndexRouteObject,
  Outlet,
  redirect,
  redirectDocument,
  RouterProvider
} from 'react-router-dom';
import { ErrorHandlerPage } from '~/pages/ErrorHandlerPage';
import { ClauseManagementPage } from '~/pages/ClauseManagementPage';
import { HelpAndSupportPage } from '~/pages/HelpAndSupportPage';
import { HelpAndSupportGreatformsPage } from '~/pages/HelpAndSupportGreatformsPage';
import { PropertiesPage } from '~/pages/PropertiesPage';
import { TransactionNew } from '~/pages/TransactionNew';
import { PropertyFromEnvelope } from '~/pages/PropertyFromEnvelope';
import { ShortId } from '@property-folders/common/util/url';
import { buildYDoc } from '@property-folders/components/form-gen-util/buildYDoc';
import { PropertyBackgroundJobs } from '@property-folders/components/context/PropertyBackgroundJobs';
import { PropertyAccessDenied, YAccessRequiredCheck } from '@property-folders/components/context/YAccessRequiredCheck';
import { TransactionHomePage } from '~/pages/TransactionHomePage';
import { TransactionAudit } from '~/pages/TransactionAudit';
import { ContractManagement } from '~/pages/ContractManagement';
import { SubscriptionFormEditPage } from '~/pages/SubscriptionFormEditPage';
import { SigningPage } from '~/pages/SigningPage';
import { ExternalPropertyUpdateReviewPage } from '~/pages/ExternalPropertyUpdateReviewPage';
import { ProspectivePurchasersPage } from '~/pages/ProspectivePurchasersPage';
import { ProspectivePurchasersAddPage } from '~/pages/ProspectivePurchasersAddPage';
import { OfferManagementPage } from '~/pages/OfferManagementPage';
import { OrderPage } from '~/pages/OrderPage';
import { SubscriptionFormCode } from '@property-folders/common/subscription-forms';
import { EpfIframePage } from '~/pages/EpfIframePage';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { PhpPage } from '~/pages/PhpPage';
import LoginPage from '~/LoginPage';
import { FullPageLoading } from '@property-folders/components/dragged-components/FullPageLoading';
import { AgentInfoState } from '~/App';
import { AppContainer } from '~/AppContainer';
import { FormsListPage } from '~/pages/FormsListPage';
import { FoldersListPage } from '~/pages/FoldersListPage';
import { CreateFormPage } from '~/pages/CreateFormPage';
import { ExtraFormCode, FormCode, FormSigningState, PropertyRootKey, TransactionMetaData } from '@property-folders/contract/yjs-schema/property';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { RumPageSubscriber } from '@property-folders/components/telemetry/RumPageSubscriber';
import { RumAgentSession } from '@property-folders/components/telemetry/RumAgentSession';
import { PropertyFolderReport } from '~/pages/PropertyFolderReport';
import { EntitySettingsNavMenuPage } from '~/pages/settings/EntitySettingsNavMenuPage';
import { EntitySettingsEditPage } from '~/pages/settings/EntitySettingsEditPage';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { GenerateSubscriptionFolderSigningSession } from '~/pages/GenerateSubscriptionFolderSigningSession';
import { SubscriptionFolder } from '~/pages/SubscriptionFolder';
import { SubscriptionFormDocument } from '~/pages/SubscriptionFormDocument';
import { TitleListPage } from '~/pages/TitleListPage';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Predicate } from '@property-folders/common/predicate';
import { NavigateWithParams, paramRegex } from '~/pages/NavigateWithParams';
import { FormsApi } from '@property-folders/common/client-api/formsApi';
import type { RouteObject } from 'react-router-dom';
import { CustomCoverFieldConfigPage } from '~/pages/CustomCoverFieldConfigPage';

export function RoutedApp({ onLogoutClick, sessionState, agentInfo, needsReload, forceTelemetryCollection }: {
  onLogoutClick: () => void,
  sessionState?: string,
  agentInfo: AgentInfoState,
  needsReload: boolean,
  forceTelemetryCollection: boolean
}) {
  const {
    instance: yManagerInstance,
    loaded: yManagerLoaded
  } = useContext(YManagerContext);
  const { instance: fileSync } = useContext(FileSyncContext);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  const onlineEffectHasRun = useRef(false);
  const online = useOnline();

  useEffect(()=>{
    // Placed here because if we put useOnline in SetupFileSyncContext, it'll re-instantiate the
    // filesync instance

    if (!fileSync) return;
    fileSync.suppressIfOffline(online);

    if (!onlineEffectHasRun.current) {
      onlineEffectHasRun.current = true; // skip initial render
      return;
    }
    if (!online) return;
    FileSync.triggerSync(fileSync);
  }, [!!online, fileSync]);

  useEffect(()=>{
    yManagerInstance?.setIsOnline(!!online);
  }, [!!online, yManagerInstance]);

  async function loadYdocMatch(match: LoaderFunctionArgs) {
    const {
      params: {
        propertyIdRaw
      }
    } = match;
    const transId = ShortId.toUuid(propertyIdRaw);
    const { ydoc, localProvider } = buildYDoc(transId);
    await localProvider.whenSynced;

    return { ydoc, localProvider, transId };
  }

  const reloadRef = useRef(needsReload);

  /**
   * Override the current redirect path.
   */
  type RedirectWithParamsHandlerFnRedirect = (path: string) => void;

  /**
   * `params` is a key-value object where each key is the `{param}` passed in `redirectPath`,
   * the value is the correlating value from URL search params, otherwise an empty string.
   * If the value returned is an object, this will be used as the values in the `redirectPath`,
   * otherwise if a string is returned, this will be the new redirect path.
   */
  type RedirectWithParamsHandlerFn = (params: { [k: string]: string }, redirect: RedirectWithParamsHandlerFnRedirect) => Promise<{ [k: string]: string } | string> | { [k: string]: string } | string;

  /**
   * Create redirect using search query string, and returning URL params.
   *
   * Usage: `redirectWithParams('index.php', '/test/path/{MyID}/here')`
   *
   * Result: `index.php?MyID=123` -> `/test/path/123/here`.
   */
  function redirectWithParams(path: string, redirectPath: string, handler?: RedirectWithParamsHandlerFn): RouteObject {
    return {
      path,
      element: <NavigateWithParams />,
      loader: async (match) => {
        const searchParamsMap = new URLSearchParams(new URL(match.request.url).search);
        const searchParams = Object.fromEntries(searchParamsMap);
        // Get all params from the redirect path, replace with search params if any, otherwise an empty string.
        const params: { [k: string]: string } = [...redirectPath.matchAll(paramRegex)].reduce((acc, match) => {
          const matchGroup1 = match[1];
          return { ...acc, [matchGroup1]: searchParams[matchGroup1] ?? '' };
        }, {});

        const redirect: RedirectWithParamsHandlerFnRedirect = (path) => redirectPath = path;

        // Allow manipulation of param values.
        const handledParams = handler ? await handler(params, redirect) : params;

        return { additionalQueryParams: handledParams, redirectPath };
      }
    };
  }

  useEffect(()=>{
    reloadRef.current = needsReload;
  }, [needsReload]);

  const router = useMemo(() => {
    const reloadIfNeeded: NonIndexRouteObject['loader'] = ({ request }) => {
      if (reloadRef.current) {
        return redirectDocument(request.url);
      }
      return null;
    };
    return createBrowserRouter([
      {
        path: '',
        errorElement: <ErrorHandlerPage />,
        children: [
          {
            path: '/',
            element: <Navigate to='/index.php' />,
            loader: reloadIfNeeded
          },
          {
            path: 'clauses',
            element: <AppContainer onLogoutClick={onLogoutClick}><ClauseManagementPage /></AppContainer>
          },
          {
            path: 'help-and-support',
            element: <AppContainer onLogoutClick={onLogoutClick}><HelpAndSupportPage /></AppContainer>
          },
          {
            path: 'help-and-support/greatforms',
            element: <AppContainer onLogoutClick={onLogoutClick}><HelpAndSupportGreatformsPage /></AppContainer>
          },
          {
            path: 'agency-settings/:entityUuidRaw?',
            element: <AppContainer onLogoutClick={onLogoutClick}>
              <EntitySettingsNavMenuPage>
                <Outlet />
              </EntitySettingsNavMenuPage>
            </AppContainer>,
            children: [
              {
                path: ':settingsGroup/:settingId?',
                element: <EntitySettingsEditPage />
              }]
          },
          {
            path: 'folders',
            element: <AppContainer onLogoutClick={onLogoutClick}><FoldersListPage /></AppContainer>
          },
          {
            path: 'folders/:folderId/documents',
            element: <AppContainer onLogoutClick={onLogoutClick}><FormsListPage isFolder /></AppContainer>
          },
          // This ensures that if FolderID is passed to `savedforms.php`, then it will be passed where defined in redirectPath.
          redirectWithParams('savedforms.php', '/folders/{FolderID}/documents'),
          {
            path: 'properties',
            element: <AppContainer onLogoutClick={onLogoutClick}><PropertiesPage /></AppContainer>,
            loader: reloadIfNeeded
          },
          {
            path: 'properties/new',
            element: <AppContainer onLogoutClick={onLogoutClick}><TransactionNew /></AppContainer>,
            loader: reloadIfNeeded
          },
          {
            path: 'subscription-folder/generate/:documentId',
            element: <AppContainer onLogoutClick={onLogoutClick}><GenerateSubscriptionFolderSigningSession /></AppContainer>
          },
          {
            path: 'subscription-folder/:propertyIdRaw',
            loader: async (match) => {
              if (!yManagerInstance) {
                throw new Error('YManager instance not found!');
              }
              const {
                params: {
                  propertyIdRaw
                }
              } = match;
              const transId = ShortId.toUuid(propertyIdRaw);
              const { ydoc, localProvider, ydocStats, localProviderStats } = buildYDoc(transId);
              const awareness = yManagerInstance.bindAwareness(ydoc);
              const cleanup = () => {
                yManagerInstance.unbindAwareness(ydoc);
              };
              yManagerInstance.markUserViewing(transId, true);
              // localProvider is awaited rather than the buildYdoc whenSynced,
              // as to not scupper offline functionality
              await localProvider.whenSynced;
              await localProviderStats.whenSynced;

              return {
                transId,
                ydoc,
                ydocStats,
                localProvider,
                localProviderStats,
                awareness,
                cleanup
              };
            },
            element: <AppContainer onLogoutClick={onLogoutClick}>
              <SubscriptionFolder />
            </AppContainer>,
            children: [
              {
                path: 'document/:documentIdRaw',
                element: <SubscriptionFormDocument />
              },
              {
                path: 'sign/:documentIdRaw/as/:partyIdRaw',
                loader: async (match) => {
                  const {
                    params: {
                      documentIdRaw,
                      partyIdRaw
                    }
                  } = match;

                  const formId = ShortId.toUuid(documentIdRaw);
                  const partyId = ShortId.toUuid(partyIdRaw);

                  return {
                    formId,
                    partyId
                  };
                },
                element: <SigningPage />
              }
            ]
          },
          {
            path: 'properties/from-envelope/:envelopeId',
            element: <AppContainer onLogoutClick={onLogoutClick}><PropertyFromEnvelope /></AppContainer>,
            loader: reloadIfNeeded
          },
          {
            // this could be of the following forms
            // * {uuid}
            // * {shortid}
            // * property-address-bits-{uuid}
            // * property-address-bits-{shortid}
            path: 'properties/:propertyIdRaw',
            loader: async (match) => {
              if (!yManagerInstance) {
                throw new Error('YManager instance not found!');
              }
              const {
                params: {
                  propertyIdRaw
                }
              } = match;
              const transId = ShortId.toUuid(propertyIdRaw);
              const { ydoc, localProvider, ydocStats, localProviderStats } = buildYDoc(transId);
              const awareness = yManagerInstance.bindAwareness(ydoc);
              const cleanup = () => {
                yManagerInstance.unbindAwareness(ydoc);
              };
              yManagerInstance.markUserViewing(transId, true);
              // localProvider is awaited rather than the buildYdoc whenSynced,
              // as to not scupper offline functionality
              await localProvider.whenSynced;
              await localProviderStats.whenSynced;

              return {
                transId,
                ydoc,
                ydocStats,
                localProvider,
                localProviderStats,
                awareness,
                cleanup
              };
            },
            element: <YAccessRequiredCheck
              deniedElement={id => <PropertyAccessDenied propertyId={id}/>}
            >
              <PropertyBackgroundJobs useLoader={true}>
                <Outlet />
              </PropertyBackgroundJobs>
            </YAccessRequiredCheck>,
            children: [
              {
                index: true,
                element: <AppContainer onLogoutClick={onLogoutClick}><TransactionHomePage /></AppContainer>
              },
              (sessionInfo?.isGlobalAdmin || sessionInfo?.impersonator)
                ? { path: 'explore', element: <AppContainer onLogoutClick={onLogoutClick}><TransactionHomePage special='explore' /></AppContainer> }
                : undefined,
              {
                path: 'audit',
                element: <AppContainer onLogoutClick={onLogoutClick}><TransactionAudit /></AppContainer>
              },
              {
                path: 'document/:documentIdRaw',
                element: <AppContainer onLogoutClick={onLogoutClick}><TransactionHomePage /></AppContainer>
              },
              {
                path: 'document/:documentIdRaw/cover-config',
                element: <AppContainer onLogoutClick={onLogoutClick}><CustomCoverFieldConfigPage /></AppContainer>
              },
              {
                path: 'contracts/template',
                element: <AppContainer onLogoutClick={onLogoutClick}><TransactionHomePage special='templateContract' /></AppContainer>
              },
              {
                path: 'contracts/:documentIdRaw',
                element: <AppContainer onLogoutClick={onLogoutClick}><TransactionHomePage special='vendorToSignContract' /></AppContainer>
              },
              {
                path: 'contracts',
                element: <AppContainer onLogoutClick={onLogoutClick}><ContractManagement /></AppContainer>
              },
              {
                path: 'subscription/:documentIdRaw',
                loader: async (match) => {
                  const {
                    params: {
                      documentIdRaw
                    }
                  } = match;

                  const formId = ShortId.toUuid(documentIdRaw);

                  return {
                    formId
                  };
                },
                element: <AppContainer onLogoutClick={onLogoutClick} moreSeriousOffline={true}><SubscriptionFormEditPage /></AppContainer>
              },
              {
                path: 'sign/:documentIdRaw/as/:partyIdRaw',
                loader: async (match) => {
                  const {
                    params: {
                      documentIdRaw,
                      partyIdRaw
                    }
                  } = match;

                  const formId = ShortId.toUuid(documentIdRaw);
                  const partyId = ShortId.toUuid(partyIdRaw);

                  return {
                    formId,
                    partyId
                  };
                },
                element: <AppContainer onLogoutClick={onLogoutClick}><SigningPage /></AppContainer>
              },
              {
                path: 'review-external-update',
                element: <AppContainer onLogoutClick={onLogoutClick}><ExternalPropertyUpdateReviewPage /></AppContainer>
              },
              {
                path: 'prospective-purchasers',
                element: <AppContainer onLogoutClick={onLogoutClick}><ProspectivePurchasersPage /></AppContainer>
              },
              {
                path: 'prospective-purchasers/add',
                element: <AppContainer onLogoutClick={onLogoutClick}><ProspectivePurchasersAddPage /></AppContainer>
              },
              {
                path: 'documents',
                element: <AppContainer onLogoutClick={onLogoutClick}><FormsListPage fromNewPropertyFolder={true} /></AppContainer>
              },
              {
                path: 'documents/create',
                element: <AppContainer onLogoutClick={onLogoutClick}><CreateFormPage propertyFolder={true} /></AppContainer>
              },
              {
                path: 'offer-management',
                element: <AppContainer onLogoutClick={onLogoutClick}><OfferManagementPage /></AppContainer>,
                children: [{
                  path: ':purchaserIdRaw',
                  element: <AppContainer onLogoutClick={onLogoutClick}><OfferManagementPage /></AppContainer>
                }]
              },
              {
                path: 'linkcreate/subsequent',
                loader: async (match) => {
                  const { ydoc, transId } = await loadYdocMatch(match);
                  const metadata = ydoc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData;
                  const headline = generateHeadlineFromMaterialisedData(ydoc.getMap(PropertyRootKey.Data).toJSON());
                  const saaFam = metadata?.formStates?.[FormCode.RSAA_SalesAgencyAgreement];
                  const existingUnsignedSubsequents = saaFam?.instances
                    ?.filter(inst => inst.formCode === ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent)
                    ?.filter(inst => inst.signing?.state !== FormSigningState.Signed);
                  if (!Array.isArray(existingUnsignedSubsequents)) {
                    return redirect(LinkBuilder.propertyPath({ id: transId, nicetext: headline }));
                  }

                  if (existingUnsignedSubsequents.length) {
                    // In general, there should only be one unsigned subsequent, so we'll just pick the last one in the
                    // list
                    const useInst = existingUnsignedSubsequents[existingUnsignedSubsequents.length - 1];
                    return redirect(LinkBuilder.documentPath(
                      { id: transId, nicetext: headline },
                      { id: useInst.id, nicetext: FormTypes[useInst.formCode].label },
                      false
                    ));
                  }

                  const {
                    clauseChildId,
                    formId,
                    documentId
                  } = await handleNewForm(ydoc, ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent, fileSync) ?? {};
                  if (!formId) throw new Error('Form not generated');
                  return redirect(LinkBuilder.documentPath(
                    { id: transId, nicetext: headline },
                    { id: formId, nicetext: FormTypes[ExtraFormCode.CRSSA_SalesAgencyAgreementSubsequent].label },
                    false
                  ));
                },
                element: <AppContainer onLogoutClick={onLogoutClick}><FullPageLoading textMessage='Generating form...' /></AppContainer>,
                errorElement: <AppContainer onLogoutClick={onLogoutClick}><FullPageLoading textMessage='Could not generate form' /></AppContainer>
              },
              {
                path: 'order/form-1',
                loader: async (match) => {
                  // I have not yet found how to obtain the ydoc we got as part of the loader above
                  // this so that we don't have to re-retrieve it here
                  // Answer is here
                  // https://remix.run/docs/en/1.19.3/pages/faq#how-can-i-have-a-parent-route-loader-validate-the-user-and-protect-all-child-routes
                  // It's just not available

                  const { ydoc, transId } = await loadYdocMatch(match);

                  // At the moment we do not look for form 1 ordering state data in sublineages
                  const metadata = ydoc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData;
                  const form1State = metadata.formStates?.[FormCode.Form1];
                  const insts = form1State?.instances;
                  if (!(insts && Array.isArray(insts) && insts.length)) {
                    return null;
                  }
                  const latestInstance = insts[insts.length - 1];
                  if (!latestInstance) {
                    return null;
                  }
                  const headline = generateHeadlineFromMaterialisedData(ydoc.getMap(PropertyRootKey.Data).toJSON());
                  const formType = FormTypes[latestInstance.formCode];
                  return redirect(LinkBuilder.documentPath({ id: transId, nicetext: headline }, {
                    id: latestInstance.id,
                    nicetext: formType.label
                  }, !!formType.subscription));
                },
                element: <AppContainer onLogoutClick={onLogoutClick} moreSeriousOffline={true}><OrderPage formCode={SubscriptionFormCode.SAF001V2_Form1} /></AppContainer>
              }
            ].filter(Predicate.isNotNull)
          },
          {
            path: 'form1_dashboard.php',
            element:
              <AppContainer onLogoutClick={onLogoutClick} moreSeriousOffline={true}>
                <EpfIframePage
                  url={LinkBuilder.reaformsFromRoot('/form1_dashboard.php')}
                  needsReload={needsReload}
                />
              </AppContainer>,
            loader: (match: any) => {
              const params = new URLSearchParams(new URL(match.request.url).search);
              return {
                additionalQueryParams: Object.fromEntries(params)
              };
            }
          },

          {
            path: 'formscreated.php',
            element: <Navigate to='/forms' />
          },

          // May not be necessary if this is an internal admin page only.
          // Just ensures bookmarks will be redirected successfully.
          {
            path: 'admin/admin_formlist.php',
            element: <Navigate to='/admin/admin_devform.php' />
          },

          {
            path: 'formsarchived.php',
            element: <Navigate to='/forms' />
          },

          {
            path: 'forms',
            element: <AppContainer onLogoutClick={onLogoutClick}><FormsListPage /></AppContainer>
          },

          // No param:
          // - /formsopen.php -> /forms
          // Invalid FormID:
          // - /formsopen.php?FormID=abc -> /forms
          // Valid FormID (moduleId can be found):
          // - /formsopen.php?FormID=132 -> /forms/categories/1002/forms/132/documents
          redirectWithParams('formsopen.php', '/forms/categories/{formModuleId}/forms/{FormID}/documents', async (params, redirect) => {
            const { FormID } = params;
            const formIdAsNumber = FormID !== undefined && !isNaN(parseInt(FormID)) ? parseInt(FormID) : undefined;

            if (formIdAsNumber === undefined) {
              redirect('/forms');
              return params;
            }

            const { results } = await FormsApi.getModuleIdFromForm({ formId: formIdAsNumber });

            if (!results) {
              redirect('/forms');
              return params;
            }

            params.formModuleId = results.moduleId.toString();
            return params;
          }),

          // May use `forms/categories` at some point in the future.

          // Reserve route.
          // Should come into effect within the next couple months.
          {
            path: 'forms/categories/:formModuleId/forms',
            element: <AppContainer onLogoutClick={onLogoutClick}>Modules coming soon...</AppContainer>
          },

          {
            path: 'forms/categories/:formModuleId/forms/:formId/documents',
            element: <AppContainer onLogoutClick={onLogoutClick}><FormsListPage isForm /></AppContainer>
          },

          {
            path: 'forms/create',
            element: <AppContainer onLogoutClick={onLogoutClick}><CreateFormPage /></AppContainer>
          },

          {
            path: 'admin/reports/property-folders',
            element: <AppContainer onLogoutClick={onLogoutClick}><PropertyFolderReport /></AppContainer>
          },

          {
            path: 'titles',
            element: <AppContainer onLogoutClick={onLogoutClick}><TitleListPage /></AppContainer>
          },

          // php routes
          ...[
            'index.php',
            'formsnew.php',
            'user_profile.php',
            'emailstatus.php',
            'esignstatus.php',
            'forms_import.php',
            'user_form_templates.php',
            'user_email_templates.php',
            'clauses.php',
            'change_password.php',
            'admin/admin_devform.php',
            'admin/admin_devform_documents.php',
            'admin/admin_users.php',
            'admin/admin_agents.php',
            'admin/admin_trials.php',
            'admin/admin_formstats.php',
            'admin/admin_allformscreated.php',
            'admin/admin_topformslist.php',
            'admin/admin_topagencylist.php',
            'admin/admin_topformslist_agency.php',
            'admin/admin_agencystats.php',
            'admin/admin_report.php',
            'admin/admin_email_templates.php',
            'admin/admin_profile.php',
            'admin/admin_monthly.php',
            'admin/admin_oasis.php',
            'admin/admin_titles.php',
            'admin/admin_smsstats.php',
            'admin/admin_emailstats.php',
            'admin/admin_formscreatedstats.php',
            'admin/admin_expired_subscriptions.php',
            'admin/admin_expired_trials.php',
            'admin/admin_clauses_groups.php',
            'admin/admin_subscriptions.php',
            'admin/admin_auditview.php',
            'remotecompletion.php',
            'remotesign.php',
            'remotecompleted.php',
            'epf_logout.php'
          ].map(r => ({
            path: r,
            element: <AppContainer onLogoutClick={onLogoutClick} moreSeriousOffline={true}>
              <PhpPage url={LinkBuilder.reaformsFromRoot(`/${r}`)} />
            </AppContainer>,
            loader: (match: any) => {
              const shouldReload = reloadIfNeeded(match);
              if (shouldReload != null) return shouldReload;
              const params = new URLSearchParams(new URL(match.request.url).search);
              return {
                additionalQueryParams: Object.fromEntries(params)
              };
            }
          })),

          // php routes with no sidebar
          ...[
            'forms.php'
          ].map(r => ({
            path: r,
            element: <AppContainer onLogoutClick={onLogoutClick} showSideBar={false} moreSeriousOffline={true}>
              <PhpPage url={LinkBuilder.reaformsFromRoot(`/${r}`)} needsReload={needsReload} />
            </AppContainer>,
            loader: (match: any) => {
              const params = new URLSearchParams(new URL(match.request.url).search);
              return {
                additionalQueryParams: Object.fromEntries(params)
              };
            }
          })),

          // redirects
          {
            path: 'login',
            element: sessionState === 'success' ? <Navigate to='/' /> : <LoginPage />
          },
          {
            path: 'propertyfolders.php',
            element: <Navigate to='/properties' />
          },
          {
            path: '/',
            index: true,
            element: <Navigate to='/index.php' />
          },
          {
            path: '',
            index: true,
            element: <Navigate to='/index.php' />
          }
        ]
      }]
    );
  }, [onLogoutClick]);

  if (!yManagerLoaded) {
    return <FullPageLoading />;
  }

  if (!(agentInfo.agentId && agentInfo.agentUuid)) {
    return <FullPageLoading />;
  }

  return <>
    <RouterProvider router={router} />
    <RumPageSubscriber router={router} />
    <RumAgentSession sessionMeta={agentInfo} forceCollection={forceTelemetryCollection} />
  </>;
}
