import { Card, Col, Container, Row } from 'react-bootstrap';
import { ExternalReviewParty } from '@property-folders/components/dragged-components/external-review/ExternalReviewParty';
import React, { useEffect, useState } from 'react';
import {
  ExternalProposedChanges
} from '@property-folders/components/dragged-components/external-review/ExternalProposedChanges';
import {
  ExternalReviewCardHeader
} from '@property-folders/components/dragged-components/external-review/ExternalReviewCardHeader';
import { EnvelopeApi } from '@property-folders/common/client-api/envelopeApi';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import { BreadCrumbs } from '@property-folders/components/dragged-components/BreadCrumbs';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { MaterialisedPropertyData } from '@property-folders/contract';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import {
  SetupNetStateWritingYjsDocContext
} from '@property-folders/components/form-gen-util/yjsStore';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { ExternalReviewUpdate } from '@property-folders/common/types/ExternalPropertyUpdates';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { RouterData } from '~/App';
import { OfflinePage } from '@property-folders/components/display/errors/offline-page';
import { useOnline } from '@property-folders/components/hooks/useOnline';

export function InnerPage() {
  const routerData = useReactRouterData<RouterData>();
  const [data, setData] = useState<ExternalReviewUpdate[]>([]);
  const [currentUpdate, setCurrentUpdate] = useState<ExternalReviewUpdate | null>(null);
  const [loading, setLoading] = useState(false);
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const navigate = useNavigate();
  const headline = generateHeadlineFromMaterialisedData(transRoot);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();

  useEffect(() => {
    if (!routerData.transId) {
      return;
    }

    EnvelopeApi.getExternalUpdates(routerData.transId).then(u => {
      if (u) {
        setData(u);
        setCurrentUpdate(u.length > 0 ? u[0] : null);
      }
    });
  }, [routerData?.transId]);

  return <Container fluid className='mt-3'>
    <Row>
      <Col xs={12}>
        <div className='w-100'>
          <div className='d-flex flex-row align-items-center justify-content-between px-3'>
            <h1 className="display-6 flex-grow-3 py-1">{headline}</h1>
          </div>
          <div className='d-flex flex-row align-items-center justify-content-between w-100 mb-3 px-3'>
            <div>
              <BreadCrumbs
                segments={[
                  { label: 'Properties', href: '/properties/' },
                  {
                    label: headline || 'Property Overview',
                    href: `/properties/${LinkBuilder.seoFriendlySlug(routerData.transId, headline)}`
                  },
                  { label: 'Review External Updates' }
                ]}
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>

    <Row>
      <Col xs={12} md={3}>
        {currentUpdate && data.map((d, idx) => <ExternalReviewParty
          name={d.name}
          position={d.position}
          status={d.status}
          reviewTime={d.time}
          selected={d.id === currentUpdate.id}
          className={idx > 0 ? 'mt-3' : ''}
          onClick={() => setCurrentUpdate(d)}
        />)}
      </Col>

      <Col xs={12} md={9} className='mt-3 mt-md-0'>
        <Card>
          <Card.Header style={{ backgroundColor: 'white' }}>
            {currentUpdate && <ExternalReviewCardHeader currentUpdate={currentUpdate}
              timezone={sessionInfo?.timeZone ?? 'Australia/Adelaide'} />}
          </Card.Header>

          <Card.Body style={{}}>
            {currentUpdate && <><h5>Changes Proposed</h5>
              <ExternalProposedChanges
                id={currentUpdate.id}
                updates={currentUpdate.updates}
                setUpdates={updates => {
                  setCurrentUpdate(u => {
                    if (!u) {
                      return u;
                    }

                    return {
                      ...u,
                      updates
                    };
                  });
                }}
              />
              <div className='d-flex w-100 mt-3'>
                <AsyncButton className='ms-auto' onClick={async () => {
                  try {
                    await EnvelopeApi.applyExternalUpdate({
                      propertyId: routerData.transId,
                      envelopeId: currentUpdate?.id,
                      updates: currentUpdate?.updates
                        .filter(u => u.acceptChange)
                        .map(u => u.fieldName)
                    });

                    navigate(`/properties/${LinkBuilder.seoFriendlySlug(routerData.transId, headline)}`);
                  } catch (e) {
                    console.warn(e);
                  }
                }}>Apply</AsyncButton>
              </div>
            </>}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>;
}

export function ExternalPropertyUpdateReviewPage() {
  const { ydoc, transId, awareness } = useReactRouterData<RouterData>();
  const isOnline = useOnline();
  if (!isOnline) {
    return <OfflinePage />;
  }
  return <SetupNetStateContext ydoc={ydoc} transactionRootKey={PropertyRootKey.Data}>
    <SetupNetStateWritingYjsDocContext
      ydoc={ydoc}
      awareness={awareness}
      docName={transId}
      transactionRootKey={PropertyRootKey.Data}
      transactionMetaRootKey={PropertyRootKey.Meta}
    >
      <InnerPage />
    </SetupNetStateWritingYjsDocContext>
  </SetupNetStateContext>;
}
