import { Allotment, LayoutPriority } from 'allotment';
import { SigningSession } from '@property-folders/components/dragged-components/signing/SigningSession';
import {
  FormInstance,
  PropertyRootKey,
  MaterialisedPropertyData, FormCode, SignedStates
} from '@property-folders/contract/yjs-schema/property';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { PDFViewer } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { Button } from 'react-bootstrap';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import React, { useCallback, useEffect, useState } from 'react';
import { Y } from '@syncedstore/core';
import { usePdfPreviewUrl } from '@property-folders/components/dragged-components/subscriptionForms/hooks';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';
import { useSigningNavProps } from '@property-folders/components/hooks/useSigningNavProps';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { applyPreviewWatermark } from '@property-folders/common/subscription-forms/apply-preview-watermark';
import { highlightTemplateForm1Fill } from '@property-folders/common/subscription-forms/highlight-template-form1-fill';

enum AllotmentPaneMode {
  Both = 0,
  Content = 1,
  Preview = 2
}

interface Props {
  yDoc: Y.Doc,
  formInstance: FormInstance;
  onCancel: () => void;
}

export function SubscriptionFormOutForSigning({ yDoc, formInstance, onCancel }: Props) {
  const formCode = formInstance.formCode;
  const formId = formInstance.id;

  const { bindState: dataBindState } = useImmerYjs<MaterialisedPropertyData>(yDoc, PropertyRootKey.Data);
  const { data } = dataBindState<MaterialisedPropertyData>(m => m);
  const splitEnabled = useMediaQuery({ minWidth: BP_MINIMA.xl });
  const [allotmentPaneMode, setAllotmentPaneMode] = useState(splitEnabled ? AllotmentPaneMode.Both : AllotmentPaneMode.Content);
  const renderTextLayer = useBreakpointValue({ base: false, sm: true }, true);
  const {
    signingSessionWizardPropsForSidebar,
    partyGroups,
    serveToPurchaserProps,
    signingMainProps
  } = useSigningNavProps({
    signing: formInstance.signing,
    formCode: formInstance.formCode
  });
  const headline = generateHeadlineFromMaterialisedData(data);

  const onVisibleChange = useCallback((changedIndex: number, changedNowVisible: boolean) => {
    if (!changedNowVisible) {
      return setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Preview : AllotmentPaneMode.Content);
    }
    if (splitEnabled) {
      return setAllotmentPaneMode(AllotmentPaneMode.Both);
    }

    setAllotmentPaneMode(changedIndex === 0 ? AllotmentPaneMode.Content : AllotmentPaneMode.Preview);
  }, [splitEnabled]);

  useEffect(() => {
    if (splitEnabled) {
      setAllotmentPaneMode(AllotmentPaneMode.Both);
    } else {
      setAllotmentPaneMode(AllotmentPaneMode.Content);
    }
  }, [splitEnabled]);

  const showContentPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Content;
  const showPreviewPane = allotmentPaneMode === AllotmentPaneMode.Both || allotmentPaneMode === AllotmentPaneMode.Preview;
  const { pdfPreviewUrl, pdfLoadErrorMessage, regenerateForDownloadPrintUsage, cannotProgressWhileOffline } = usePdfPreviewUrl({
    form: formInstance,
    yDoc,
    formCode,
    formId,
    previewPdfTransforms: FormTypes[formCode]?.formFamily === FormCode.Form1 && SignedStates.has(formInstance.signing.state) ? [applyPreviewWatermark, highlightTemplateForm1Fill ]: []
  });

  const formName = formInstance.upload?.name ?? formInstance.subscription?.fileName ?? formInstance.formCode;
  const fileName = formName ? `${formName} - ${headline}.pdf` : `${headline}.pdf`;

  return <Allotment snap onVisibleChange={onVisibleChange}>
    <Allotment.Pane minSize={300} preferredSize={760} priority={LayoutPriority.High}
      className={'overflow-auto'}
      visible={showContentPane}>
      <SigningSession
        formCode={formCode}
        formId={formId}
        ydoc={yDoc}
        onVoid={onCancel}
        wizardSectionProps={signingSessionWizardPropsForSidebar}
        dataRootKey={PropertyRootKey.Data}
        metaRootKey={PropertyRootKey.Meta}
        partyGroups={partyGroups}
        serveToPurchaserProps={serveToPurchaserProps}
        signingMainProps={signingMainProps}
      />
    </Allotment.Pane>
    <Allotment.Pane minSize={300} visible={showPreviewPane}>
      <SetupPdfLoadStateContext>
        <PDFViewer
          filename={fileName}
          pdfUrl={pdfPreviewUrl}
          bookmark={''}
          allowPrint={true}
          contextDependentLoadingMessage={pdfLoadErrorMessage}
          cannotProgressWhileOffline={cannotProgressWhileOffline}
          toolbarRight={(
            <Button variant="secondary" onClick={() => setAllotmentPaneMode(AllotmentPaneMode.Content)}>
              <Icon name="close" />
            </Button>
          )}
          offsetRightByDragBar={allotmentPaneMode === AllotmentPaneMode.Both}
          renderTextLayer={renderTextLayer}
          standalonePreview={true}
          onDocumentRegenerate={regenerateForDownloadPrintUsage}
        />
      </SetupPdfLoadStateContext>
    </Allotment.Pane>
  </Allotment>;
}
