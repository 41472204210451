import { JsonEditor } from '~/components/JSONEditor';
import { useEntityYDoc } from '@property-folders/components/hooks/useEntityYDoc';
import { ChangeSet, deepEditObjectInPlace, ObjectDiff } from '@property-folders/common/util/object';
import { Button, Col, Row } from 'react-bootstrap';
import { ChangesetTable } from '~/components/ChangesetTable';
import { useEffect, useState } from 'react';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { FormCode, SigningPartyType } from '@property-folders/contract';

export function GreatformsSigningDefaultsEditor({ entityUuid }: { entityUuid: string }) {
  const { bindState: entityBindState } = useEntityYDoc(entityUuid);
  const entityYDoc = entityBindState<EntitySettingsEntity>(state => state);
  const [dataChangeSet, setDataChangeSet] = useState<ChangeSet>([]);
  const dataJson = entityYDoc.data?.signingOptions?.formSpecific;

  useEffect(() => {
    if (!dataJson) {
      entityYDoc.update?.(state => {
        if (!state.signingOptions) {
          state.signingOptions = {};
        }

        state.signingOptions.formSpecific = {};
      });
    }
  }, [dataJson]);

  const updateStateClick = () => {
    entityYDoc.update?.((state: any) => {
      if (!state.signingOptions) {
        state.signingOptions = {};
      }

      for (const change of dataChangeSet) {
        deepEditObjectInPlace(state.signingOptions.formSpecific, change.key, change.to);
      }
    });

    setDataChangeSet([]);
  };

  const onChangeData = (newJson: any) => {
    if (!dataJson) {
      return;
    }

    const changeSet = ObjectDiff.getChangeset(dataJson, newJson).filter(s => s.type !== 'UNCHANGED');
    setDataChangeSet(changeSet);
  };

  const signingPartySources = [
    SigningPartyType.SignOnline,
    SigningPartyType.SignInPerson,
    SigningPartyType.SignWet,
    SigningPartyType.SignOnlineSms
  ];

  return <>
    <Row>
      <Col>
        <JsonEditor
          onChangeJSON={onChangeData}
          jsonData={dataJson ?? {}}
          schema={{
            additionalProperties: false,
            type: 'object',
            properties: {
              [FormCode.RSAA_SalesAgencyAgreement]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.RSC_ContractOfSale]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.Form1]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.LicenceToOccupy]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.OfferToPurchase]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.VendorQuestionnaire]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.StorySheet]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.OpenInspection]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.Auction]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.PurchaserManagement]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.OfferManagement]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.ContractManagement]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.AllDocuments]: { $ref: '#/$defs/formSigningInfo' },
              [FormCode.UploadedDocument]: { $ref: '#/$defs/formSigningInfo' }
            },
            $defs: {
              formSigningInfo: {
                type: 'object',
                additionalProperties: false,
                properties: {
                  enable: {
                    type: 'array',
                    items: {
                      enum: signingPartySources
                    }
                  },
                  disable: {
                    type: 'array',
                    items: {
                      enum: signingPartySources
                    }
                  },
                  default: {
                    enum: signingPartySources
                  },
                  automaticDistribution: {
                    type: 'boolean'
                  }
                }
              }
            }
          }}
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <div className='d-flex'>
          <h3>Data Changeset</h3>
          <Button
            className='ms-auto'
            onClick={() => updateStateClick()}
          >
            Update
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <ChangesetTable changeSet={dataChangeSet} />
      </Col>
    </Row>
  </>;
}
