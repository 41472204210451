import { Button, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useReactRouterData } from '@property-folders/components/hooks/useReactRouterHooks';
import {
  generateHeadlineFromMaterialisedData
} from '@property-folders/common/yjs-schema/property';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import {
  MaterialisedPropertyData,
  OfferMeta,
  ProspectivePurchaserGroup,
  ProspectivePurchaserParty,
  PurchaserPortalSharedDocument,
  PurchaserProcessAction,
  PurchaserProcessMeta,
  PurchaserProcessStepType,
  TransactionMetaData
} from '@property-folders/contract';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  InfiniteScrollListColumn,
  LazyInfiniteTableList
} from '@property-folders/components/dragged-components/LazyInfiniteTableList';
import './ProspectivePurchasers.scss';
import { ProcessEditor, ProcessStep, PurchaserProcessStep } from '@property-folders/components/dragged-components/ProcessEditor';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { capitalize, keyBy, mapValues, orderBy, sortBy } from 'lodash';
import QRCode from 'qrcode';
import clsJn from '@property-folders/common/util/classNameJoin';
import { PurchaserApi } from '@property-folders/common/client-api/purchaserApi';
import { Pdf } from '@property-folders/common/util/pdf-worker/Pdf';
import { getPurchaserSelfRegistrationDefinition } from '@property-folders/common/util/pdfgen/definitions/documents/purchaser-self-registration';
import { useBrandConfig } from '@property-folders/components/hooks/useEntity';
import { useEntityLogoForCurrentPropertyFolder } from '@property-folders/components/hooks/useEntityLogo';
import {
  useDeletePurchaserPortalDocumentMutation,
  useGetPurchaserPortalQuery, usePostPurchaserPortalDocumentMutation,
  useUpdatePurchaserPortalSettingsMutation
} from '@property-folders/common/redux-reducers/restApiSlice';
import { formatTimestamp, prettifyPhone } from '@property-folders/common/util/formatting';
import { EntityImageType } from '@property-folders/contract/rest/property';
import { PurchaserPortalSettings, SettingsOfferType } from '@property-folders/contract/yjs-schema/purchaser-portal';
import { RouterData } from '~/App';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { SelfRegistrationModal } from '@property-folders/components/dragged-components/property/prospective-purchasers/SelfRegistrationModal';
import { UploadDocumentModal } from '@property-folders/components/dragged-components/property/prospective-purchasers/UploadDocumentModal';
import { transparentPixel } from '@property-folders/common/util/pdfgen/logos';
import { ShortId } from '@property-folders/common/util/url';
import { Predicate } from '@property-folders/common/predicate';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { SplitIfBelowBreakpointButton } from '@property-folders/components/dragged-components/SplitIfBelowBreakpointButton';
import { processPdf } from '@property-folders/common/util/pdf/pdf-process';
import { ButtonWithOfflineTooltip } from '@property-folders/components/dragged-components/TooltipWhenDisabled';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { v4 } from 'uuid';
import { OfflineQueuedApi } from '@property-folders/common/offline/pending-api-queue';

export const formatPartyDetails = (party: ProspectivePurchaserParty | undefined, afterNameAction?: ReactNode) => {
  return <div className={'d-flex flex-column m-0'}>
    <div className={'fw-bold'}>{party?.fullLegalName}{afterNameAction && <span className='ms-2'>{afterNameAction}</span>}</div>
    <div className={'fw-normal font-underline small text-secondary'} style={{ marginTop: -4 }}>{party?.email}</div>
    <div className={'fw-normal small text-secondary'} style={{ marginTop: -1 }}>{prettifyPhone(party?.phone, false, undefined, false)}</div>
  </div>;
};

export const formatStatus = (status: string | undefined) => {
  return status
    ? <div className={clsJn(['Hidden', 'Disabled'].includes(status) ? 'text-secondary' : '')}>{capitalize(status)}</div>
    : '';
};

export function InnerPage() {
  const routerData = useReactRouterData<RouterData>();
  const [queryBits, setQueryBits] = useSearchParams();
  const queryIdRaw = queryBits.get('id');
  const queryId = queryIdRaw && ShortId.toUuid(queryIdRaw);
  const queryName = queryBits.get('name');
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ });
  const { value: offerManagement } = useLightweightTransaction<OfferMeta>({ bindToMetaKey: true, myPath: 'offerManagement' });
  const { value: proposedPortalId } = useLightweightTransaction<OfferMeta>({ bindToMetaKey: true, myPath: 'proposedPortalId' });
  const { updateDraft: updateMeta } = useYdocBinder<TransactionMetaData>({
    path: '',
    bindToMetaKey: true
  });
  const { value: meta } = useLightweightTransaction<TransactionMetaData>({  bindToMetaKey: true, myPath: '' });
  const transactionMetaMap = routerData.ydoc.getMap(PropertyRootKey.Meta);
  const navigate = useNavigate();
  const headline = generateHeadlineFromMaterialisedData(transRoot);
  const [searchTerm, setSearchTermInner] = useState<string>(queryName??'');
  const setSearchTerm = (term: string) => {
    setSearchTermInner(term);
    setQueryBits(term ? { name: term } : undefined);
  };
  const [showSelfRegistrationModal, setShowSelfRegistrationModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [registrationPdfUrl, setRegistrationPdfUrl] = useState<string>();
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState<string>();
  const brands = useBrandConfig();
  const entityLogoUri = useEntityLogoForCurrentPropertyFolder();
  const { data: portal, isLoading } = useGetPurchaserPortalQuery(offerManagement?.portalId, { skip: !offerManagement?.portalId });
  const [ updatePortalSettings] = useUpdatePurchaserPortalSettingsMutation();
  const [ deletePurchaserPortalDocument ] = useDeletePurchaserPortalDocumentMutation();
  const [ postPurchaserPortalDocument ] = usePostPurchaserPortalDocumentMutation();
  const [prospectivePurchasers, setProspectivePurchasers] = useState<ProspectivePurchaserGroup[]>([]);
  const documentTypes: PurchaserPortalSharedDocument['documentType'][] = ['brochure'];
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const isOnline = useOnline();
  useEffect(() => {
    const changeFunc = () => {
      setProspectivePurchasers(transactionMetaMap.toJSON()?.offerManagement?.prospectivePurchasers ?? []);
    };

    setProspectivePurchasers(transactionMetaMap.toJSON()?.offerManagement?.prospectivePurchasers ?? []);
    transactionMetaMap.observeDeep(changeFunc);

    return () => transactionMetaMap.unobserveDeep(changeFunc);
  }, [routerData.ydoc]);

  const entityId = meta?.entity?.id;
  const registrationUrl = offerManagement?.portalId??proposedPortalId
    ? new URL(LinkBuilder.purchaserPortal({ id: offerManagement?.portalId??proposedPortalId, nicetext: headline }), LinkBuilder.portalSiteBase).toString()
    : '';

  useEffect(() => {
    if (!session) return;
    if (!transRoot?.id) return;
    if (!meta) return;

    let localProposedPortalId = meta.proposedPortalId;
    // Noting that proposedPortalId is now created at portal creation, so we can't rely on that not being present
    // to decide whether to run/queue the API call
    if (!localProposedPortalId && offerManagement?.portalId) {
      localProposedPortalId = offerManagement.portalId;
      updateMeta?.(draft => {
        draft.proposedPortalId = localProposedPortalId;
      });
    } else if (!localProposedPortalId) {
      localProposedPortalId = v4();

      updateMeta?.(draft => {
        draft.proposedPortalId = localProposedPortalId;
      });
    }
    if (offerManagement?.portalId) return;
    // If we call create with this ID multiple times, should be fine, the server should reject it

    if (isOnline) {
      PurchaserApi.createPortal(transRoot.id, localProposedPortalId);
      return;
    }
    OfflineQueuedApi.createPortal(transRoot.id, localProposedPortalId, session);
  }, [transRoot?.id, isOnline, session, !!meta]);
  useEffect(() => {
    if (!registrationUrl) return;
    QRCode.toDataURL(registrationUrl).then((url:string) => setQrCodeImageUrl(url));
  }, [registrationUrl]);

  useEffect(() => {
    if (!entityId || !meta || !transRoot || !qrCodeImageUrl) return;
    const pdf = new Pdf();
    const brandReg = brands.getPurchaserRegistrationConfig(entityId);
    const headerImageUrl = brandReg?.headerImageS3Uri ? LinkBuilder.entityImage(entityId, EntityImageType.PurchaserSelfRegistrationHeader) : transparentPixel;
    pdf.prepare(brands.getFormConfig(entityId), {});
    pdf.generateBlob(getPurchaserSelfRegistrationDefinition(transRoot, LinkBuilder.reaformsFromRoot(headerImageUrl, false), brandReg, qrCodeImageUrl), (data)=>{
      setRegistrationPdfUrl(URL.createObjectURL(data));
    });
  }, [entityLogoUri, qrCodeImageUrl, entityId]);

  const getLatestAction = (actions: PurchaserProcessMeta[]) => {
    return orderBy(actions, a => a.date, 'desc')?.[0];

  };

  const columnDefinition: InfiniteScrollListColumn<ProspectivePurchaserGroup>[] = [
    {
      label: 'Primary Purchaser',
      rowMajor: (rowData: ProspectivePurchaserGroup) => formatPartyDetails(
        rowData?.purchaserParties?.find(pp => pp.id === rowData.primaryPurchaser)
      )
    },
    {
      label: 'Other Purchasers',
      rowMajor: (rowData: ProspectivePurchaserGroup) => <div className={'d-flex gap-4'}>
        {rowData?.purchaserParties?.length > 1
          ? rowData?.purchaserParties?.filter(pp => pp.id !== rowData.primaryPurchaser)?.map(p => formatPartyDetails(p))
          : <div className={'text-secondary'}>None</div>}
      </div>,
      headerCellStyle: { width: '25%' }
    },
    {
      label: 'Registration',
      rowMajor: (rowData: ProspectivePurchaserGroup) => formatStatus(getLatestAction(rowData.meta?.registration || [])?.action, { propertyId: transRoot?.id, portalId: portal?.portalId, portalUserId: rowData.id }),
      rowMinor: (rowData: ProspectivePurchaserGroup) => formatTimestamp(getLatestAction(rowData.meta?.registration || [])?.date, '', false),
      headerCellStyle: { width: '15%' }
    },
    {
      label: 'Form 1',
      rowMajor: (rowData: ProspectivePurchaserGroup) => formatStatus(getLatestAction(rowData.meta?.form1 || [])?.action),
      rowMinor: (rowData: ProspectivePurchaserGroup) => formatTimestamp(getLatestAction(rowData.meta?.form1 || [])?.date, '', false),
      headerCellStyle: { width: '15%' }
    },
    {
      label: 'Template Contract',
      rowMajor: (rowData: ProspectivePurchaserGroup) => formatStatus(getLatestAction(rowData.meta?.exampleContract || [])?.action),
      rowMinor: (rowData: ProspectivePurchaserGroup) => formatTimestamp(getLatestAction(rowData.meta?.exampleContract || [])?.date, '', false),
      headerCellStyle: { width: '15%' }
    },
    {
      label: 'Offer Document',
      rowMajor: (rowData: ProspectivePurchaserGroup) => {
        const latestAction = getLatestAction(rowData.meta?.offerDocument || []);
        return latestAction?.action === PurchaserProcessAction.disabled ? formatStatus(PurchaserProcessAction.disabled) : formatStatus(latestAction?.type);
      },
      rowMinor: (rowData: ProspectivePurchaserGroup) => getLatestAction(rowData.meta?.offerDocument || [])?.action === PurchaserProcessAction.created
        ? formatTimestamp(getLatestAction(rowData.meta?.offerDocument || [])?.date, '', false) : '',
      headerCellStyle: { width: '15%' }
    }
  ];

  const rowActions = [
    {
      label: 'Withdraw Invite',
      action: (rowData: ProspectivePurchaserGroup) => PurchaserApi.deleteInvitedPortalUser({ propertyId: transRoot?.id, portalId: portal?.portalId, portalUserId: rowData.id }),
      if: (rowData: ProspectivePurchaserGroup) => getLatestAction(rowData.meta?.registration || [])?.action === PurchaserProcessAction.invited,
      disableIfOffline: true
    },
    {
      label: 'Resend Welcome Email',
      action: (rowData: ProspectivePurchaserGroup) => PurchaserApi.resendPortalWelcome({ propertyId: transRoot?.id, portalId: portal?.portalId, portalUserId: rowData.id, resendMode: 'email' }),
      if: (rowData: ProspectivePurchaserGroup) => {
        return rowData.purchaserParties.find(p=>p.id === rowData.primaryPurchaser)?.email
        && rowData.meta?.registration.map(rAct=>rAct.action).includes(PurchaserProcessAction.registered);
      }, // Registered means it was verified at some point
      disableIfOffline: true
    },
    {
      label: 'Resend Welcome SMS',
      action: (rowData: ProspectivePurchaserGroup) => PurchaserApi.resendPortalWelcome({ propertyId: transRoot?.id, portalId: portal?.portalId, portalUserId: rowData.id, resendMode: 'sms' }),
      if: (rowData: ProspectivePurchaserGroup) => {
        return rowData.purchaserParties.find(p=>p.id === rowData.primaryPurchaser)?.phone
        && rowData.meta?.registration.map(rAct=>rAct.action).includes(PurchaserProcessAction.registered);
      }, // Registered means it was verified at some point
      disableIfOffline: true
    },
    {
      label: 'Resend Invite Email',
      action: (rowData: ProspectivePurchaserGroup) => PurchaserApi.resendInvitationLink({ propertyId: transRoot?.id, portalId: portal?.portalId, portalUserId: rowData.id, resendMode: 'email' }),
      if: (rowData: ProspectivePurchaserGroup) => {
        return rowData.purchaserParties.find(p=>p.id === rowData.primaryPurchaser)?.email
        && !rowData.meta?.registration.map(rAct=>rAct.action).includes(PurchaserProcessAction.registered)
        && rowData.meta?.registration.map(rAct=>rAct.action).includes(PurchaserProcessAction.invited);
      },
      disableIfOffline: true
    },
    {
      label: 'Resend Invite SMS',
      action: (rowData: ProspectivePurchaserGroup) => PurchaserApi.resendInvitationLink({ propertyId: transRoot?.id, portalId: portal?.portalId, portalUserId: rowData.id, resendMode: 'sms' }),
      if: (rowData: ProspectivePurchaserGroup) => {
        return rowData.purchaserParties.find(p=>p.id === rowData.primaryPurchaser)?.phone
        && !rowData.meta?.registration.map(rAct=>rAct.action).includes(PurchaserProcessAction.registered)
        && rowData.meta?.registration.map(rAct=>rAct.action).includes(PurchaserProcessAction.invited);
      },
      disableIfOffline: true
    }
  ];

  const settings = {
    ...portal?.settings,
    ...portal?.settings?.offerType === SettingsOfferType.offer && { [PurchaserProcessStepType.SubmitLetterOfOffer]: true },
    ...portal?.settings?.offerType === SettingsOfferType.contract && { [PurchaserProcessStepType.SubmitContract]: true }
  };

  const processSteps = [PurchaserProcessStepType.Register, PurchaserProcessStepType.AccessR3, PurchaserProcessStepType.AccessBrochure, PurchaserProcessStepType.AccessForm1, PurchaserProcessStepType.AccessContract, PurchaserProcessStepType.SubmitLetterOfOffer, PurchaserProcessStepType.SubmitContract]
    ?.map(p => ({
      ...PurchaserProcessStep[p],
      enabled: p === PurchaserProcessStepType.AccessR3 || settings?.[p],
      stepType: p,
      availableUnauth: settings?.unauthDocs?.includes(PurchaserProcessStep[p]?.sharedDocumentType)
    })) as ProcessStep[];

  const filteredPurchasers= ((queryId ?
    prospectivePurchasers.filter(p=>p.id === queryId)
    : searchTerm
      ? prospectivePurchasers
        .filter(p=> p.purchaserParties
          .map(p => [
            p.fullLegalName?.toLowerCase(),
            p.email?.toLowerCase(),
            p.phone?.toLowerCase(),
            p.phone?.replace('+61', '0')?.toLowerCase()
          ].filter(Predicate.isNotNull).join(',')).join(',')
          .includes(searchTerm.toLowerCase())
        )
      : prospectivePurchasers)??[])
    .filter(p => p.meta?.registration.find(r => r.action === PurchaserProcessAction.registered || r.action === PurchaserProcessAction.invited));

  const sortedPurchasers = sortBy(filteredPurchasers, p=> p?.purchaserParties?.[0]?.fullLegalName);
  const offerManagementPath = LinkBuilder.propertyPagePath({ id: routerData.transId, nicetext: headline }, 'offer-management');
  const prospectivePurchasersAddPath = LinkBuilder.propertyPagePath({ id: routerData.transId, nicetext: headline }, 'prospective-purchasers/add');

  const breadcrumbs = useMemo(() => [
    { label: 'Properties', href: '/properties/' },
    { label: headline || 'Property Overview', href: `/properties/${LinkBuilder.seoFriendlySlug(routerData.transId, headline)}` },
    { label: 'Prospective Purchasers' }
  ], [headline]);

  const afterTitle = <SplitIfBelowBreakpointButton
    boundaryBreakpoint={'sm'}
    aboveBreakpointRenderer={children => <InputGroup className='gap-2 flex-row-reverse w-auto'>{children}</InputGroup>}
  >
    <Button onClick={()=>navigate(offerManagementPath)} variant="primary">Offers</Button>
    <ButtonWithOfflineTooltip tooltipBelow={true} onClick={()=>navigate(prospectivePurchasersAddPath)} variant="outline-secondary">Invite</ButtonWithOfflineTooltip>
    <Button tooltipBelow={true} onClick={()=>setShowSelfRegistrationModal(true)} variant="outline-secondary">Self-Registration</Button>
    <ButtonWithOfflineTooltip tooltipBelow={true} onClick={()=>setShowDocumentModal(true)} variant="outline-secondary">Documents</ButtonWithOfflineTooltip>{/* All actions including view are online actions */}
  </SplitIfBelowBreakpointButton>;

  return <ContentTitler title={'Prospective Purchasers'} breadcrumbs={breadcrumbs} afterTitle={afterTitle} flex={true} scroll={false}>
    <Row className={'px-3 mt-1 mb-2 d-flex flex-row g-0'}>
      <Col lg={8} sm={8} xs={12}>
        {isLoading
          ? <Spinner animation={'border'} style={{ width: '30px', height: '30px' }}/>
          : <ProcessEditor
            enableROR3={true}
            steps={processSteps}
            processForText='property'
            forProperty={true}
            onUpdate={(steps: ProcessStep[])=> {
              updatePortalSettings({ portalId: portal?.portalId, settings: mapStepsToSettings(steps, true) });
            }}
          />
        }
      </Col>
      <Col lg={4} sm={4} xs={12} className={'mt-1'}>
        <InputGroup className={'embed-icon-wrapper ms-auto'}>
          <Form.Control
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            placeholder="Name, email, or phone..."
            className={'embed-icon-target'}
          />
          <Icon name={'search'} variant={'outlined'} icoClass={'embed-icon'} style={{ marginTop: '-3px' }}></Icon>
        </InputGroup>
      </Col>
    </Row>

    <Row className='g-0 flex-grow-1 flex-shrink-1 overflow-hidden'>
      <LazyInfiniteTableList
        storageKey='prospective-purchasers'
        hasNextPage={false}
        items={sortedPurchasers}
        columns={columnDefinition}
        rowActions={rowActions}
        rowClick={(rowData)=> navigate(`${offerManagementPath}/${rowData.id}`)}
        containerClass={'mw-100 mt-0'}
        headerClass={'purchasers-table-header'}
        rowHeight={'68px'}
      />
    </Row>
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={showSelfRegistrationModal} onClose={()=>setShowSelfRegistrationModal(false)} />}>
      <SelfRegistrationModal
        visible={showSelfRegistrationModal}
        onClose={()=>setShowSelfRegistrationModal(false)}
        processSteps={processSteps}
        registrationUrl={registrationUrl}
        pdfUrl={registrationPdfUrl}
        qrCodeUrl={qrCodeImageUrl}
        warnUserNotYetCreated={!isOnline&&!offerManagement?.portalId}
      />
    </ErrorBoundary>
    <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={showDocumentModal} onClose={() => setShowDocumentModal(false)} />}>
      <UploadDocumentModal
        visible={showDocumentModal}
        onClose={() => setShowDocumentModal(false)}
        documents={documentTypes.map(dt => (
          portal?.sharedDocuments.find(sd => sd.documentType === dt) ?? { documentType: dt })
        )}
        onUpload={async (documentType, file) => {
          if (!portal?.portalId) {
            return false;
          }

          const processed = await processPdf(await file.arrayBuffer());
          if (processed?.isEncrypted) {
            console.error('pdf is encrypted');
            return false;
          }
          if (!processed?.pdf) {
            console.error('could not process pdf');
            return false;
          }

          const response = await postPurchaserPortalDocument({
            portalId: portal.portalId,
            documentType,
            fileName: file.name
          });

          if ('error' in response) {
            console.log(response);
            return false;
          }

          await fetch(response.data.url, {
            body: processed.pdf,
            method: 'PUT',
            mode: 'cors',
            // for some reason the api isn't returning directed headers,
            // so this needs to be done manually now that we are sending the processed file rather than
            // the OG File (with its content type embedded)
            headers: new Headers({ 'content-type': file.type })
          });
          return true;
        }}
        onDelete={async documentId => {
          if (!portal?.portalId) {
            return Promise.resolve(false);
          }
          await deletePurchaserPortalDocument({ portalId: portal.portalId, documentId });

          return Promise.resolve(true);
        }}
      />
    </ErrorBoundary>
  </ContentTitler>;
}

export function ProspectivePurchasersPage() {
  const { ydoc, transId, awareness } = useReactRouterData<RouterData>();
  return <SetupNetStateContext ydoc={ydoc} transactionRootKey={PropertyRootKey.Data}>
    <SetupNetStateWritingYjsDocContext
      ydoc={ydoc}
      awareness={awareness}
      docName={transId}
      transactionRootKey={PropertyRootKey.Data}
      transactionMetaRootKey={PropertyRootKey.Meta}
    >
      <InnerPage />
    </SetupNetStateWritingYjsDocContext>
  </SetupNetStateContext>;
}

export const mapStepsToSettings = (steps: ProcessStep[], generalPortalSettings?: boolean) => {
  const settings = mapValues(keyBy(steps, s => s.name), s => s.enabled || s.name === PurchaserProcessStepType.Register);
  if (generalPortalSettings) {
    const unauthDocs = steps.filter(s=>s.availableUnauth).map(s=>s.sharedDocumentType).filter(s=>typeof s === 'string');
    settings.unauthDocs = unauthDocs;
  }
  settings.offerType = settings[PurchaserProcessStepType.SubmitLetterOfOffer] ? SettingsOfferType.offer : settings[PurchaserProcessStepType.SubmitContract] ? SettingsOfferType.contract : SettingsOfferType.none;
  delete settings[PurchaserProcessStepType.SubmitLetterOfOffer];
  delete settings[PurchaserProcessStepType.SubmitContract];
  return settings as unknown as PurchaserPortalSettings;
};
