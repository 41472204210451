import { Button, Modal } from 'react-bootstrap';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';

export function SessionExpiredPopup(props: {fromOffline?: boolean, returnPath: string|undefined}) {

  return <Modal show={true}>
    <Modal.Header>
      <Modal.Title>Login Expired</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Your login has expired. Please login again.</p>
      {props.fromOffline && <p>Your changes made offline will be transmitted after you login.</p>}
    </Modal.Body>
    <Modal.Footer><Button onClick={()=>{
      window.location.href = LinkBuilder.loginPage(props.returnPath).toString();
    }}>Login</Button></Modal.Footer>
  </Modal>;
}
